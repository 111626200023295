import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { SimpleContentPage } from '@mangoart/gatsby-ui/components/ezagrar/SimpleContentPage';
import DefaultLayout from '../layout/DefaultLayout';
import * as styles from './datenschutz.module.css';

const DatenschutzPage = ({ data, location }) => {
  const { pageData, siteData } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <SimpleContentPage {...content} />
    </DefaultLayout>
  );
};

export default DatenschutzPage;

export const DatenschutzPageQuery = graphql`
  query DatenschutzPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageDatenschutz {
      content {
        title
        pageID
        teaser
        text
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
